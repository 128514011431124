import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import Image from '../../../../../gatsby-theme-husky/src/common/Image';
import ArticleRecommendedProducts from '../../../../../gatsby-theme-husky/src/components/ArticleSection/ArticleRecommendedProducts/ArticleRecommendedProducts';
import { ArticleSectionProps } from '../../../../../gatsby-theme-husky/src/components/ArticleSection/models';
import OrderedList from '../../../../../gatsby-theme-husky/src/components/OrderedList';
import Video from '../../../../../gatsby-theme-husky/src/components/Video';
import { DangerouslySetInnerHtml } from '../../../../../gatsby-theme-husky/src/layout';

import '../../../../../gatsby-theme-husky/src/components/ArticleSection/ArticleSection.scss';
import './ShadowArticleSection.scss';

export const ArticleSection = ({
  title,
  description,
  media: [media],
  weRecommended: [weRecommended],
  instruction: [instruction],
}: ArticleSectionProps) => {
  const isRecommendedExists = !!weRecommended;
  const isInstructionsExists = !!instruction;
  const isVideoExists = !!(media && media.videoId);
  const isImageExists = !!(media && media.image);
  const isFullImageExists = !!(media && media.fullImage);
  const isTitleExists = !!title;

  const isStandaloneParagraph = [
    isRecommendedExists,
    isInstructionsExists,
    isVideoExists,
    isImageExists,
    isTitleExists,
  ].every((item) => !item);

  const CSSclassNames = classNames(`article-section`, {
    'article-section__standalone-paragraph row': isStandaloneParagraph,
  });

  return (
    <Row>
      <Col>
        <article data-testid="article-section" className={CSSclassNames}>
          {isStandaloneParagraph ? (
            <Col>
              <DangerouslySetInnerHtml html={description} />
            </Col>
          ) : (
            <>
              <Row className="article-section-content">
                <Col>
                  {isTitleExists ? (
                    <DangerouslySetInnerHtml
                      className="article-section__title"
                      element="h2"
                      html={title}
                    />
                  ) : null}
                  <DangerouslySetInnerHtml
                    html={description}
                    className="list__marker-in-circle list__bullet-marker"
                  />
                </Col>
              </Row>
              {isFullImageExists ? (
                <Row>
                  <Col>
                    <Image imageData={media.fullImage} alt={media.alt || ''} />
                  </Col>
                </Row>
              ) : isImageExists || isVideoExists ? (
                <Row>
                  <Col>
                    {isImageExists && media.image !== null ? (
                      <Image imageData={media.image} alt={media.alt || ''} />
                    ) : (
                      <Video videoId={media.videoId} />
                    )}
                  </Col>
                </Row>
              ) : null}
              {isRecommendedExists || isInstructionsExists ? (
                <Row>
                  {isRecommendedExists ? (
                    <Col lg={isInstructionsExists ? 6 : 12}>
                      <h3 className="article-section__subtitle">
                        {weRecommended.recommendedLabel}
                      </h3>
                      <ArticleRecommendedProducts recommended={weRecommended.recommended} />
                    </Col>
                  ) : null}
                  {isInstructionsExists ? (
                    <Col lg={isRecommendedExists ? 6 : 12}>
                      <h3 className="article-section__subtitle article-section__subtitle--instructions">
                        {instruction.instructionLabel}
                      </h3>
                      <OrderedList
                        orderedList={instruction.instructions}
                        moreButtonText={instruction.instructionMoreButtonLabel}
                        numberOfDisplayedItems={4}
                      />
                    </Col>
                  ) : null}
                </Row>
              ) : null}
            </>
          )}
        </article>
      </Col>
    </Row>
  );
};

export default ArticleSection;
